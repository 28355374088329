@import "../../assets/styles/global.scss";

#exercises-page {
	#page-wrapper {
		padding: 2rem;
	}

	.flexy {
		display: flex;
		flex-wrap: wrap;

		h5 {
			display: inline-block;
			float: left;
		}
	}	

	input {
	    height: 60px;
	    font-size: 1.5rem;
	}

	#button-container {
		margin: 0 0 2rem;
		overflow-x: scroll;
		overflow-y: hidden;
		white-space: nowrap;

		/* Hide scrollbar */
		&::-webkit-scrollbar {
		  display: none;
		}

		.btn {
			display: inline-block;

			&:not(:first-child) {
				margin-left: 1rem;
			}
		}
	}

	#add-new-exercise-section {
		margin-bottom: 2rem;
		box-shadow: $sharp-shadow;
		padding: 1rem;
		border: 1px solid #dddddd;
		background-color: lightgray;
		border-radius: 5px;
	}

	#exercise-type-section {
		margin-bottom: 1rem;
		
		> div {
			width: 100%;

			button {
				width: 50%;
			}
		}
	}	

	#exercise-img-section {
	    background: #eeeeee;
	    padding: 1rem;
	    border-radius: 5px;		
	}

	#top-exercises-section .card {
		background-color: #eee;
	}
}