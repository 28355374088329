.card.panel {
	border-radius: 4px;

	h2 {
		margin: 0;
		padding: 1rem;
	}

	.card-title {
		margin: 0;
	}

	.card-body {
		padding: 1rem;
		background-color: white;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}		
}