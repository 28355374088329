@import "../../assets/styles/global.scss";
@import url('https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&display=swap');

#add-exercise-modal {
	hr {
		border: 5px solid #111111;
		opacity: 1;
		border-radius: 5px;	
		margin: 3rem 0;
	}

	h4 {
		font-size: 2rem;
	}

	.modal-content {
		background-color: #0075D9;
	}

	.modal-body {
		position: relative;
	}

	#workout-name,
	#workout-name::placeholder,
	select,
	#unit-dropdown,
	input[name='duration'] {
		font-size: 2rem;
		text-align: center;
	}

	#bb-img {
		margin-top: -1rem !important;
		display: block;
		margin: auto;
		padding: 0.5rem;
		box-shadow: $smooth-shadow;
		border: 1px solid gray;

		&:not(.bb-selected) {
			background-color: white;
			border-radius: 10px;			
		}
	}

	#sets {
		text-align: center;
		// color: #0074D9;
	}

	#sets,
	#reps-header,
	imput {
		text-align: center;
		font-family: "Kode Mono", monospace;
		font-weight: bold;
		font-size: 2.5rem;

		span {
			font-family: "Kode Mono", monospace;
		}
	}

	#weight,
	#reps {
		font-size: 15vw;
		color: black;
		text-align: center;
		border: 1px solid gray;
		background: white;
	}

	.bb-selected {
		background: #2ECC40;
		border-radius: 10px;	
	}

	#sets-section {
		display: flex;
		overflow-x: auto;
		width: auto;

		> div {
			&:not(:first-child) {
				margin-left: 2rem;
			}
			width: 100%;
		}

		[id*='set-'] {
		    zoom: 0.8;
		    background: #eeeeee;
		    padding: 1rem;
		    border-radius: 5px;
		    border: 2px solid #dddddd;		    
		}	
	}

	.weights-section {
		margin-bottom: 2rem;
		margin: 0 0 2rem;
		overflow-x: scroll;
		overflow-y: hidden;
		white-space: nowrap;
	    padding-top: 0.5rem;
	    border: 1px solid gray;
	    box-shadow: $smooth-shadow;
	    padding-bottom: 0.5rem;
	    background-color: white;
	    padding: 0.5rem;
	    border-radius: 5px;
	    
		/* Hide scrollbar */
		&::-webkit-scrollbar {
		  display: none;
		}

		h6 {
			clear: both;
			padding-top: 1rem;
		}
	}

	#reps-section {
		.input-group {
			box-shadow: $smooth-shadow;
			border-radius: 5px;
		}
	}

	#weight-buttons-section {
		display: flex;
		justify-content: space-around;
		align-items: center;
		width: 100%;
	}

	.hex-img-container {
		display: inline-block;
		position: relative; // Ensure child elements can be positioned relative to the container
		height: 100px;
		width: 100px;
		background-image: url('../../assets/images/hexagon.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;

		span {
			height: 100%;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: bold;			
		}

		&.lbs-35 {
			height: 85px;
			width: 85px;
		}
		&.lbs-25 {
			height: 75px;
			width: 75px;
		}
		&.lbs-10 {
			height: 65px;
			width: 65px;
		}
		&.lbs-5 {
			height: 55px;
			width: 55px;
		}
		&.lbs-2half {
			height: 45px;
			width: 45px;
		}

		// Style for the clicks circle
		.clicks-badge {
			position: absolute;
			bottom: -10px; // Position below the hexagon
			left: 50%;
			transform: translateX(-50%);
			background-color: #0075D9;
			color: white;
			border-radius: 50%;
			width: 25px; // Adjust size as needed
			height: 25px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 0.8rem; // Adjust font size as needed
			box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
		}
	}

	@keyframes color-flash {
	    0% {
	        background-color: #2ECC40;
	        opacity:1;
	    }
	    100% {
	        background-color: transparent;
	       	opacity: 1;
	    }
	}

	#color-flash-box {
	    height: 100%; 
	    width: 100%; 
	    background-color: white;
	    position: absolute;
	    left: 5px;
	    top: 5px;
	    opacity: 0;   
	}   
	.color-flash-activate {
	    animation-name: color-flash;
	    animation-duration: 300ms;
	    animation-iteration-count: 1;
	    animation-timing-function: ease-in-out;		
	}	

	section#workout-log {
		margin-top: 2rem;
		padding: 1rem;
		border-radius: 5px;
		border: 1px solid #dddddd;
		background-color: #eeeeee;
		max-height: 350px;
		overflow-y: scroll;

		h1 {
			text-align: center;
		}
	}

	section#exercise-history-chart-section {
		
	}
}