@import url('https://fonts.googleapis.com/css2?family=Protest+Riot&display=swap');

#home-page {
	.App-logo {
	  height: 40vmin;
	  pointer-events: none;
	}

	@media (prefers-reduced-motion: no-preference) {
	  .App-logo {
	    animation: App-logo-spin infinite 20s linear;
	  }
	}

	.App-header {
	  // background-color: #282c34;
	  min-height: 100vh;
	  height: 100%;
	  width:  100%;
	  font-size: calc(10px + 2vmin);
	  color: white;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	}	

    section:nth-of-type(1) {
        padding: 2rem;
        
        [class*='col-'] {
            padding-top: 12px;
        }
    }

    #info-panel-section {
        h3 {
            margin-top: 0;
        }
    }

    .callout-panel:not(:last-of-type) {
    	margin-bottom: 2rem;
    }

	#last-workout-section {
	  padding: 1rem;
	  background: linear-gradient(145deg, #1a1a1a, #111111);
	  margin: 0 auto;
	  color: #f5f5f5;
	  text-align: center;
	  min-height: 120px;
	  // border-radius: 10px;
	  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
	  transition: transform 0.3s ease, box-shadow 0.3s ease;

	  &:hover {
	    transform: translateY(-3px);
	    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.7);
	  }

	  h1 {
	    font-size: 2rem;
	    margin: 0;
	    font-weight: 600;
	    color: #ffffff;
	    cursor: pointer;
	    line-height: 1.4;
	    transition: color 0.3s ease;

	    &:hover {
	      // color: #1db954; // Accent color for hover effect
	    }

	    small {
	      display: block;
	      color: #aaaaaa;
	      font-size: 1.2rem;
	      opacity: 0.8;
	      font-style: italic;
	    }
	  }

	  hr {
	    border: 0;
	    border-top: 1px solid #444444;
	    margin: 1rem auto;
	    width: 80%;
	    opacity: 0.7;
	  }

	  p {
	    font-weight: 400;
	    font-style: normal;
	    font-size: 1.5rem;
	    color: #e0e0e0;
	    margin: 0;
	    text-transform: capitalize;
	    transition: color 0.3s ease;

	    &:hover {
	      // color: #1db954;
	    }
	  }
	}

    section#weekly-totals-section,
    section#top-exercises-section {
    	padding: 1rem;
    	// background-color: white;

    	h1 {
    		small {
    			font-size: 1.3rem;
    			font-style: italic;
    			color: #AAAAAA;
    			opacity: 0.9;
    		}
    	}
	}
}