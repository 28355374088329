@import "../../assets/styles/global.scss";

.workout-panel {
  height: 100px;
  width: 100%;
  padding: 1rem;
  border-radius: 10px;
  background-blend-mode: darken; // Blend mode to darken the image
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.5); // Add a semi-transparent black overlay
  color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  position: relative;
  cursor: pointer;
  box-shadow: $sharp-shadow;

  h3 {
    font-weight: bold;
  }

  .health-icon {
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 50px;
  }
}

.workout-panel + small {
  font-size: 0.8rem;
  color: #111111;
  opacity: 0.8;
}
