@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

* {
  // font-family: 'Roboto', sans-serif;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;  
}

h1, h2, h3, h4 {
  // font-family: 'Oswald', sans-serif; 
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;  
}

body  {
  // padding-top: 65px; /* This is put into place due to the fixed navbar ON TOP */  
  min-height: 100vh;
  width: 100%;  
}

[id*='-page']:not(#login-page) {
  padding-bottom: 56px !important;  
}

.App {
  /*text-align: center;*/
}

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pointer {
  cursor: pointer;
}
