#workout-calendar-page {
	#workout-panel-section {
		padding: 2rem;
	}

	#button-container {
		margin: 0 0 2rem;
		overflow-x: scroll;
		overflow-y: hidden;
		white-space: nowrap;

		/* Hide scrollbar */
		&::-webkit-scrollbar {
		  display: none;
		}

		.btn {
			display: inline-block;

			&:not(:first-child) {
				margin-left: 1rem;
			}
		}
		.btn-alt {
			color: #198754;
			background-color: transparent;
		}
	}	

	button.react-calendar__tile.highlight {
		background-color: rgba(46, 204, 64, .5);
		border-radius: 100%;
	}
}