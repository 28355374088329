@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

$smooth-shadow: 0 1px 1px rgba(0,0,0,0.15), 
  0 2px 2px rgba(0,0,0,0.15), 
  0 4px 4px rgba(0,0,0,0.15), 
  0 8px 8px rgba(0,0,0,0.15);

$sharp-shadow: 0 1px 1px rgba(0,0,0,0.25), 
  0 2px 2px rgba(0,0,0,0.20), 
  0 4px 4px rgba(0,0,0,0.15), 
  0 8px 8px rgba(0,0,0,0.10),
  0 16px 16px rgba(0,0,0,0.05);

.flexy {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  h5 {
    display: inline-block;
    float: left;
  }
}

.space-after {
  margin-bottom: 2rem;
}
.space-after-sm {
  margin-bottom: 1rem;
}

.bg-starry-night {
  background: #000 url(http://www.script-tutorials.com/demos/360/images/stars.png) repeat top center;
}