@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tourney:ital,wght@0,100..900;1,100..900&display=swap');
@import "../../assets/styles/global.scss";

@font-face {
  font-family: 'Honk';
  src: url("../../assets/fonts/Honk.ttf") format("truetype");
}

.callout-panel {
  width: 100%;
  height: 100px; // Adjustable panel height
  border-radius: 12px; // Slightly modern curve
  border: none; // Cleaner design
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
  background-color: rgba(0, 0, 0, 0.6); // Dark overlay for better text contrast
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3), 0 6px 10px rgba(0, 0, 0, 0.25);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.4), 0 8px 16px rgba(0, 0, 0, 0.3);
  }

  h1 {
    font-family: "Sixtyfour", sans-serif; // New font family
    font-size: clamp(1.5rem, 5vw, 3rem); // Dynamically adjusts font size
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.8); // Enhanced readability
    color: #f0f0f0; // Softer white for contrast
    margin: 0; // Reset default margin
    text-transform: uppercase; // Consistent modern style
  }
}
