#workout-details-page {
	.text-muted {
		font-size: 1rem;
	}

	#button-container {
	    margin: 0 0 1rem;
	    padding-bottom: 0.5rem; /* Add padding between the content and the scrollbar */
	    overflow-x: auto; /* Use auto to always show the scrollbar */
	    overflow-y: hidden;
	    white-space: nowrap;

	    /* Customize scrollbar appearance */
	    &::-webkit-scrollbar {
	        display: block; /* Ensure the scrollbar is visible */
	        height: 8px; /* Set height for horizontal scrollbar */
	    }

	    &::-webkit-scrollbar-thumb {
	        background: #ccc; /* Style the scrollbar thumb */
	        border-radius: 4px; /* Rounded corners */
	    }

	    &::-webkit-scrollbar-track {
	        background: #f1f1f1; /* Style the scrollbar track */
	    }

	    .btn {
	        display: inline-block;

	        &:not(:first-child) {
	            margin-left: 1rem;
	        }
	    }
	}

	#details-section {
		padding: 1rem;
	}

	.set-badge {
		&:not(:nth-of-type(1)) {
			margin-left: 1rem;
		}
	}

	.note-card {
		&:not(:last-of-type) {
			margin-bottom: 1rem;
		}
	}

	#radar-chart-container {
		width: 100%;
		height: 300px;	
		background-color: white;
		border: 1px solid #dddddd;
		border-radius: 5px;			
	}

	.accordion-body {
		
	}

	#icon-section {	
		display: flex;
		justify-content: center;
		align-items: center;	
		width: 100%;

		.icon-container {
			background-color: white;
			border-radius: 50%;
			padding: 5px;

			&:not(:nth-of-type(1)) {
				margin-left: 1rem;
			}

			.icon {
				width: 25px;
			}
		}
	}

	.custom-goto-button {
	  background-color: transparent; /* Transparent background */
	  border: 2px solid #007bff; /* Bold blue border */
	  color: #007bff; /* Blue text/icon color */
	  font-weight: bold; /* Bold text */
	  border-radius: 5px; /* Rounded corners */
	  padding: 8px 16px; /* Padding for a better click area */
	  cursor: pointer; /* Pointer cursor */
	  transition: all 0.3s ease; /* Smooth transition for hover effects */
	  display: inline-flex; /* Align the icon and text */
	  align-items: center; /* Center align icon vertically */
	  justify-content: center; /* Center align icon horizontally */
	  vertical-align: middle;

	  &:hover {
	    background-color: #007bff; /* Blue background on hover */
	    color: #fff; /* White text/icon color on hover */
	    border-color: #0056b3; /* Slightly darker blue border */
	  }

	  &:focus {
	    outline: none; /* Remove default focus outline */
	    box-shadow: 0 0 4px rgba(0, 123, 255, 0.8); /* Custom focus shadow */
	  }

	  &:active {
	    background-color: #0056b3; /* Even darker blue background */
	    border-color: #004085; /* Darkest blue border */
	  }

	  &:disabled {
	    background-color: transparent; /* Transparent background when disabled */
	    color: #6c757d; /* Greyed-out text/icon */
	    border-color: #6c757d; /* Greyed-out border */
	    cursor: not-allowed; /* Not-allowed cursor */
	    opacity: 0.65; /* Reduced opacity for disabled state */
	  }
	}

	.set-table {
	  display: flex; /* Flexbox for row layout */
	  flex-wrap: wrap; /* Prevent wrapping, force all cells on one line */
	  gap: 5px; /* Space between each set */
	  justify-content: flex-start; /* Align left */
	  overflow-x: auto; /* Enable horizontal scrolling if needed */
	}

	.set-cell {
	  display: flex;
	  align-items: center; /* Center the content vertically */
	  justify-content: center; /* Center the content horizontally */
	  padding: 5px; /* Reduced padding */
	  text-align: center;
	  background-color: #007bff; /* Default color */
	  color: white;
	  font-size: 12px; /* Smaller font size */
	  word-wrap: break-word;
	  width: 70px; /* Smaller fixed width for each cell */
	  height: 60px; /* Smaller height for each cell */
	  border-radius: 5px; /* Rounded corners */
	}

	.set-cell.warmup {
	  background-color: #17a2b8; /* Warmup color */
	}

	.set-cell.primary {
	  background-color: #007bff; /* Regular set color */
	}

	.set-cell:first-child {
	  border-top-left-radius: 5px; /* Rounded corners for the first cell */
	  border-bottom-left-radius: 5px;
	}

	.set-cell:last-child {
	  border-top-right-radius: 5px; /* Rounded corners for the last cell */
	  border-bottom-right-radius: 5px;
	}

	/* Media Queries for responsiveness */

	/* On mobile devices */
	@media (max-width: 767px) {
	  .set-cell {
	    font-size: 12px; /* Smaller font size for mobile */
	    padding: 2px; /* Smaller padding for mobile */
	    height: 20px; /* Adjusted height for mobile */
	    width: 60px; /* Slightly smaller width for mobile */
	  }
	}

	/* On tablets */
	@media (min-width: 768px) and (max-width: 1024px) {
	  .set-cell {
	    font-size: 12px; /* Medium font size for tablets */
	    padding: 2px; /* Adjusted padding for tablets */
	    height: 20px; /* Adjusted height for tablets */
	    width: 60px; /* Adjusted width for tablets */
	  }
	}

	/* On desktop */
	@media (min-width: 1025px) {
	  .set-cell {
	    font-size: 12px; /* Default font size for desktop */
	    padding: 2px; /* Padding for desktop */
	    height: 20px; /* Fixed height for desktop */
	    width: 60px; /* Default fixed width for desktop */
	  }
	}
}