#duration-picker {
	text-align: center;

	input {
		font-size: 2rem;
		text-align: center;
		border: none;
	}

	table {
		th {
			background: gray;
			color: white;
			font-size: 1.5rem;			
		}

		td {
			background-color: #eeeeee;
		}
	}
}