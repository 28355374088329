#exercise-details-modal {
	section#ratings {
		margin-bottom: 1rem;
		font-size: 2rem;
		text-align: center;
	}

	.flexy {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		h5 {
			display: inline-block;
			float: left;
		}
	}

	#exercise-type-section {
		margin-bottom: 2rem;

		> div {
			width: 100%;

			button {
				width: 50%;
			}
		}
	}

	#exercise-img-section {
	    background: #eeeeee;
	    padding: 1rem;
	    border-radius: 5px;		
	}

	#exercise-history-chart-section {
		overflow-x: scroll;
	}

	#workout-log {
		margin-top: 2rem;
		max-height: 400px;
		overflow-y: scroll;		
	}
}