#intensity-meter {
  // max-width: 300px;
  // margin: 0 auto;
  	
  .progress {
    height: 30px; // Height of the progress bar
    width: 100%; // Full width of the bar  	
    background-image: linear-gradient(
      to right,
      #0074d9, // Very Light (Blue)
      #7fdbff, // Light (Light Blue)
      #ffdc00, // Moderate (Yellow)
      #ff851b, // Hard (Orange)
      #ff4136  // Very Hard (Red)
    );
    border-radius: 5px; // Rounded edges for the bar
    position: relative;
    overflow: hidden; // Ensure overlay stays within bounds

    &.max {
      .progress-bar {
        background-color: #ff4136; // Very Hard (Red) for max intensity
      }
    }
  }

  .progress-bar {
    background-color: black; // Black overlay
    height: 100%; // Match the height of the parent
    width: 0; // Default to 0, dynamically updated
    transition: width 0.3s ease; // Smooth transitions for the fill
    position: absolute; // Positioned over the gradient
    top: 0;
    left: 0;
    z-index: 1; // Ensure it overlays the gradient
  }
}
