.ihs__container {
	display: flex;
	overflow-x: auto;
	width: auto;

	.color-panel-container  {
		&:not(:first-child) {
			margin-left: 1rem;
		}
	}	
}