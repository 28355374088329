@import url('https://fonts.googleapis.com/css2?family=Honk&family=Sixtyfour&display=swap');
@import '../../assets/styles/global.scss';

$fontRoboto: 'Roboto', sans-serif;
$honk: 'Honk', system-ui;

#page-hero {
	height: 400px;
	width: 100%;
	position: relative;
	text-align: center;
	overflow: hidden;
	box-shadow: $sharp-shadow;

	#copy {
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		bottom: 0;
		word-wrap: break-word;
		padding: 0.5rem;

		span {
			font-size: clamp(1.5rem, 8vw, 4rem); // Scales better for small screens
			font-family: 'Sixtyfour', sans-serif;

			@media screen and (min-width: 768px) {
				font-size: clamp(2rem, 5vw, 4rem); // Adjust for tablets
			}

			@media screen and (min-width: 1024px) {
				font-size: clamp(2.5rem, 4vw, 6rem); // For larger screens
			}
		}
	}
}
