#login-page {
    height: 100vh;
    width: 100vw;
    padding: 2rem;
    // background: url("../../assets/images/ananta_banner_multicolor.jpg");
    background-color: #0074D9;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    #twoPointOh {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        width: 150px;
    }

    #login-container {
        @media screen and (min-width: 768px) {
            width: 400px;
        }

        background: rgba(0,0,0,0.7);
        padding: 1rem;
        border-radius: 5px;
        position: relative;

        #login-form {
            label,
            input {
                color: white;
            }

            input {
                background-color: transparent;
                border: none;
                color: white;
                border-radius: 0;
                border-bottom: 1px solid white;
                font-style: italic;

                &::placeholder {
                    font-style: normal;
                }

                &.form-control:focus {
                    color: white;
                    background-color: transparent;
                    border: none;
                    border-bottom: 1px solid white;
                    outline: 0;
                    box-shadow: none;
                }
            }

            #forgot-password__copy {
                margin-top: 1rem;
                margin-bottom: 1rem;
                cursor: pointer;
                -webkit-transition: color 0.3s ease-in-out;
                -moz-transition: color 0.3s ease-in-out;
                -ms-transition: color 0.3s ease-in-out;
                -o-transition: color 0.3s ease-in-out;
                transition: color 0.3s ease-in-out;

                &:hover {
                    color: white;
                }
            }
        }
    }

    .social-icon__container {
        height: 30px;
        width: 30px;
        border-radius: 3px;
        position: absolute;
        top: 0.5rem;
        right: 1rem;
        border: 1px solid #111111;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}