#user-profile-form {
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;

  // Wrapper container for the form
  .user-profile-form-container {
    background-color: #f9f9f9;
    border-radius: 8px;
    // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 0 auto;
    max-width: 800px;
  }

  // Form labels
  .user-profile-form-container .form-label {
    font-weight: 600;
    font-size: 14px;
    color: #333;
  }

  // Form inputs
  .user-profile-form-container .form-control {
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 14px;
    transition: border-color 0.3s;

    &:focus {
      border-color: #007bff;
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    }
  }

  // Row styling for responsiveness
  .user-profile-form-container .row {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  // Button styling
  .user-profile-form-container .btn {
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    padding: 10px 15px;
    text-transform: uppercase;
    transition: background-color 0.3s;

    &:hover {
      background-color: #0056b3;
    }
  }

  // Headings
  .user-profile-form-container h1,
  .user-profile-form-container h2,
  .user-profile-form-container h3 {
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
  }

  // General spacing adjustments
  .user-profile-form-container .form-group {
    margin-bottom: 20px;
  }

  // Goals table
  .user-profile-form-container .goals-table {
    margin-top: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
  }

  .user-profile-form-container .goals-table th,
  .user-profile-form-container .goals-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  .user-profile-form-container .goals-table th {
    background-color: #f7f7f7;
    font-weight: 600;
    color: #333;
  }

  .user-profile-form-container .goals-table tr:last-child td {
    border-bottom: none;
  }

  // Goal type dropdown
  .user-profile-form-container .goal-type-dropdown {
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 14px;
    background-color: #fff;
    color: #333;
    appearance: none;
    width: 100%;
  }

  .user-profile-form-container .goal-type-dropdown:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
}