@import "../../assets/styles/global.scss";

#workout-plan-page {
	#page-wrapper {
		padding: 2rem;

		input {
			font-size: 2rem;
			text-align: center;
		}		
	}

	#exercise-type-section {
		margin-bottom: 1rem;
		
		> div {
			width: 100%;

			button {
				width: 50%;
			}
		}
	}	
}