#default-navbar {
	a.active {
		color: white;
	}

	.hidden-xs {
		@media screen and (max-width: 450px) {
			display: none;
		}
	}
}