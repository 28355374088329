@import '../../assets/styles/global.scss';

#days-in-a-row-container {
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: #111111;
	box-shadow: $sharp-shadow;
	position: relative;
	z-index: 99;

	> h1 {
		margin: 0;
		color: white;
		font-size: 1.8rem; /* Default font size */

		@media (max-width: 768px) {
			font-size: 1.4rem; /* Smaller font size for mobile */
		}

		#smiley-face {
			margin-left: 0.5rem;
			width: 50px;

			@media (max-width: 768px) {
				width: 40px; /* Reduce smiley size for mobile */
			}
		}
	}

	.icon {
		width: 50px;

		@media (max-width: 768px) {
			width: 40px; /* Scale down icons for smaller screens */
		}
	}

	#icon-section {
		text-align: center;
		margin-top: 1rem;
		display: flex;
		justify-content: center;
		flex-wrap: wrap; /* Enable wrapping for smaller screens */
		gap: 0.5rem;

		.dir-icon {
			width: 50px;

			@media (max-width: 768px) {
				width: 40px; /* Adjust icon size for mobile */
			}

			&.trophy-complete {
				font-size: 60px;

				@media (max-width: 768px) {
					font-size: 50px; /* Reduce trophy size for mobile */
				}
			}
		}
	}
}
