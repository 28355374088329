// @import url('https://fonts.googleapis.com/css2?family=Rubik+Beastly&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bigshot+One&display=swap');

#profile-page {
	h1#user-name {
	
	}

	#flexy {		
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-around;		
	}

	section#img-section {
		padding: 2rem 2rem 1rem 2rem;
		min-height: 300px;
		background-color: white;

		#wrapper {
			display: flex;
			align-items: center;
			justify-content: center;

			#profile-img-container {
				height: 250px;
				width: 250px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: auto;
				background-color: #AAAAAA;
				border-radius: 100%;
				border: 5px solid white;
				overflow: hidden;

				#profile-img {
					img {

					}
				}
			}
		}
	}

	section#color-panel-section {
		padding: 1rem;

		.color-panel-container {
			cursor: pointer; 
			
			.color-panel {
				height: 100px;
				width: 100px;
				background: #eeeeee;
				border: 2px solid #dddddd;
				border-radius: 10px;

				&.active {
					border: 5px solid #2ECC40;
				}
			}

			p {
				text-align: center;
			}
		}		
	}	
}