.workout-log {
	.accordion-body {
		overflow: scroll;
	}

	table th,
	table td {
		text-align: center;
	}

	#main-table {
		#secondary-table {

		}
	}
}