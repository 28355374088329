.intensity-meter {
  // max-width: 300px;
  // margin: 0 auto;

  .progress-bar {
    height: 30px; // Height of the progress bar
    width: 100%; // Full width of the bar
    background: linear-gradient(
      90deg,
      #0074D9, // Very Light (Blue)
      #7FDBFF, // Light (Light Blue)
      #FFDC00, // Moderate (Yellow)
      #FF851B, // Hard (Orange)
      #FF4136 // Very Hard (Red)
    );
    border-radius: 5px;
    position: relative;
    overflow: hidden; // Ensure the overlay doesn't exceed bounds
  }

  .progress-bar-overlay {
    height: 100%; // Full height of the bar
    background: black; // Overlay color
    transition: width 0.3s ease; // Smooth transition for changes
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; // Ensure overlay is on top
  }
}
