@import "../../assets/styles/global.scss";

#new-workout-form {
	padding: 1rem;
	margin-bottom: 2rem;
	box-shadow: $sharp-shadow;
	border: 1px solid #dddddd;
	background-color: lightgray;
	border-radius: 5px;		
}

#workout-name,
input[type='datetime-local'] {
    height: 60px;
    font-size: 1.5rem;
}

input[type='datetime-local'] {
    font-size: 1.4rem;
}