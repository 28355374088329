@import '../../assets/styles/global.scss';

#edit-workout-modal {
	#option-panels-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;

		.option-panel {
			width: 75px;
			height: 75px;
			padding: 1rem;
			border-radius: 5px;
			background-color: #eeeeee;
			box-shadow: $smooth-shadow;

			&.injury.active {
				background-color: #FF4136;
			}
			&.stretched.active {
				background-color: #2ECC40;
			}	
			&.sick.active {
				background-color: #2ECC40;
			}					

			img.icon {
				width: auto;
			}
		}
	}

	#workout-name {
		margin-bottom: 0.5rem;
	}

	#workout-name,
	input[type='datetime-local'],
	#training-goal-select {
	    height: 60px;
	    font-size: 1.5rem;
	    text-align: center;
	}

	input[type='datetime-local'],
	#training-goal-select {
	    font-size: 1.4rem;
	}	

	.star-rating-container {
		text-align: center;
		
		.star {
			font-size: 2rem;
		}
	}
}