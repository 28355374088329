body {
    background-color: #eee;
}

section.full {
    min-height: 100vh;
    width: 100vw;
}

/* This is to counter the body styling of padding-top: 65px;  This is put into place due to the fixed navbar */     
#home-page, 
#login-page {
    // margin-top: -65px;
}

.dashboard-panel-default {
    height: 400px;
    width: 100%;
    background-color: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #AAAAAA;
    border-radius: 2px;
    position: relative;
    padding: 1rem;
}

.dashboard-panel {
    height: 400px;
    width: 100%;
    background-color: white;    
    border: 1px solid #AAAAAA;
    border-radius: 2px;
    overflow-x: auto;
    position: relative;
    padding: 1rem;
}

#profile-page {
    // margin-top: -65px; /* This is to counter the body styling of padding-top: 65px;  This is put into place due to the fixed navbar */      

    #profile-image {
        border-radius: 10px;
        width: 500px;
        height: 500px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin: auto;
        display: block;
        box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);

        @media screen and (max-width: 768px) {
            height: 300px;
            width: 100%;
        }
    }

    #details-column {
        @media screen and (max-width: 768px) {
            margin-bottom: 1rem;
        }
    }
}

#error-page {
	padding: 0 1rem 1rem;

	.wrapper {
		min-height: 100vh;
		height: 100%;
		width:  100%;
		font-size: calc(10px + 2vmin);
		color: black;
		display: flex;
		align-items: center;
		justify-content: center;		
	}
}

@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');
#wizard-page {
    .flex-center {
      display: flex;
      align-items: center;
      justify-content: center;        
    }

    h3 {
        font-family: 'Patrick Hand', cursive;
    }
}